<template>
    <el-card class="box-card" sytle="padding: 5px;">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item  label="状态" prop="state">
                <el-select
                    v-model="form.state"
                    filterable
                    clearable
                    placeholder=""
                    class="state_c"
                >
                    <el-option
                        v-for="item in AllState"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item  label="类型" prop="type">
                <el-select
                    v-model="form.type"
                    filterable
                    clearable
                    placeholder=""
                    class="type_c"
                >
                    <el-option
                        v-for="item in AllType"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item  label="级别" prop="rank">
                <el-select
                    v-model="form.rank"
                    filterable
                    clearable
                    placeholder=""
                    class="rank_c"
                >
                    <el-option
                        v-for="item in AllRank"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="玩家" prop="staff_name">
                <el-input
                    v-model="form.staff_name"
                    placeholder="玩家搜索"
                    @keyup.enter.native="search()"
                    clearable
                    class="staff_name_c"
                ></el-input>
            </el-form-item>
            <el-form-item label="日期">
                <el-date-picker
                    v-model="form.start_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="所有时间"
                    class="time_c">
                </el-date-picker>
                至
                <el-date-picker
                    v-model="form.end_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="所有时间"
                    class="time_c">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn()">重置</el-button>
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'TaskSearchBarComponent',
    data() {
        return {
            form: {
                state: '',
                type: '',
                rank: '',
                staff_name: '',
                start_time: '',
                end_time: '',
                page: 1
            },
            AllState: [
                {id:0,name:'未完成'},
                {id:1,name:'已完成'},
                {id:2,name:'已过期'}
            ],
            AllType: [
                {id:1,name:'每日任务'},
                {id:2,name:'每周任务'},
                {id:3,name:'签到任务'},
                {id:4,name:'限时活动'},
                {id:5,name:'情人节'},
                {id:6,name:'隐藏任务'}
            ],
            AllRank: [
                {id:1,name:'简单'},
                {id:2,name:'普通'},
                {id:3,name:'困难'}
            ]
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn() {
            this.form = {
                state: '',
                type: '',
                rank: '',
                staff_name: '',
                start_time: '',
                end_time: '',
                page: 1
            }
        }
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 3px;
}
.state_c,.type_c,.rank_c {
    width: 120px;
}

.staff_name_c {
    width: 200px;
}
</style>