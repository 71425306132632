<template>
    <div>
        <task-search-bar-component
        @search="get_task_index"
        ></task-search-bar-component>
        <el-card class="box_card" v-loading.fullscreen.lock="loading">
            <el-table
                :data="table_data"
                style="width: 100%"
                border
                stripe
                highlight-current-row
            >
                <el-table-column
                    align="center"
                    type="index"
                    min-width="10px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="date"
                    label="日期"
                    min-width="100px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="task_type"
                    label="类型"
                    min-width="90px"
                >
                    <template slot-scope="scope">
                        <el-tag size="small" effect="dark" type="success" v-if="scope.row.task_type === 1">每日任务</el-tag>
                        <el-tag size="small" effect="dark" v-else-if="scope.row.task_type === 2">每周任务</el-tag>
                        <el-tag size="small" effect="dark" type="success" v-else-if="scope.row.task_type === 3">签到任务</el-tag>
                        <el-tag size="small" effect="dark" type="danger" v-else-if="scope.row.task_type === 4">限时活动</el-tag>
                        <el-tag size="small" effect="dark" type="warning" v-else-if="scope.row.task_type === 5">情人节</el-tag>
                        <el-tag size="small" effect="dark" type="info" v-else-if="scope.row.task_type === 6">隐藏任务</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="state"
                    label="状态"
                    min-width="80px"
                >
                    <template slot-scope="scope">
                        <el-tag size="small" type="info" v-if="scope.row.state === 0">未完成</el-tag>
                        <el-tag size="small" type="success" v-else-if="scope.row.state === 1">已完成</el-tag>
                        <el-tag size="small" type="info" v-else-if="scope.row.state === 2">已过期</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="task_rank"
                    label="难度"
                    min-width="60px"
                >
                    <template slot-scope="scope">
                        <el-tag size="small" effect="plain" v-if="scope.row.task_rank === 1">简单</el-tag>
                        <el-tag size="small" type="warning" effect="plain" v-else-if="scope.row.task_rank === 2">普通</el-tag>
                        <el-tag size="small" type="danger" effect="plain" v-else-if="scope.row.task_rank === 3">困难</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="task_content"
                    label="任务内容"
                    min-width="300px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="task_score"
                    label="荣誉分"
                    min-width="70px"
                />
                <el-table-column
                    align="center"
                    prop="name"
                    label="玩家"
                    min-width="150px">
                </el-table-column>
            </el-table>
        </el-card>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="total"
            @search="get_task_index"
        ></common-page-component>
    </div>
</template>

<script>
import { UserTaskIndexRequest } from '@/network/query/task.js'

import TaskSearchBarComponent from '@/components/task/TaskSearchBarComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name:'',
    data(){
        return {
            loading: true,
            cond: {
                state: '',
                type: '',
                rank: '',
                staff_name: '',
                start_time: '',
                end_time: '',
                page: 1,
                limit: 10
            },
            total: 0,
            table_data: []
        }
    },
    computed:{},
    methods:{
        get_task_index(param = {}) {

            this.loading = true
            this.cond.state = param.state ?? this.cond.state
            this.cond.type = param.type ?? this.cond.type
            this.cond.rank = param.rank ?? this.cond.rank
            this.cond.staff_name = param.staff_name ?? this.cond.staff_name
            this.cond.start_time = param.start_time ?? this.cond.start_time
            this.cond.end_time = param.end_time ?? this.cond.end_time
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            UserTaskIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.table_data = s.result.data
                        this.total = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })

                document.getElementById('el_main').scrollTop = 0;
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_task_index()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        TaskSearchBarComponent,
        CommonPageComponent
    },
    watch:{},
}
</script>

<style lang='less'></style>